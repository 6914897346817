global.recaptchaHandler = {};

recaptchaHandler.refreshRecaptcha = function (options = {}) {
  if (!options.action) return;

  if (typeof grecaptcha === 'undefined') {
    if (options.callback) options.callback();
  } else {
    grecaptcha.ready(function() {
      var contentBlock = options.facebox ?
        options.faceboxContent || $('#facebox:visible') :
        $('body');

      var idSuffix = options.action.replace(/_/g, "-"),
          input = contentBlock.find(`#g-recaptcha-response-data-${idSuffix}`),
          siteKey = input.attr('data-sitekey');

      mdFormHelper.showSpinner();

      if (grecaptcha) {
        try{
          grecaptcha.execute(siteKey, { action: options.action }).then(function(token) {
            input.val(token);
            mdFormHelper.hideSpinner();

            if (options.callback) options.callback();
          });
        } catch (e) {
          mdFormHelper.hideSpinner();
          if (options.callback) options.callback();
        }
      }
    });
  }
};

recaptchaHandler.refreshFaceboxRecaptcha = function (options = {}) {
  options.facebox = true;
  recaptchaHandler.refreshRecaptcha(options);
};

recaptchaHandler.recaptchaFacebox = function (id, action, timeout = 0) {
  $(document).trigger('close.facebox');

  var faceboxContent = $(`#${id}`).clone();

  recaptchaHandler.refreshFaceboxRecaptcha({
    action: action,
    faceboxContent: faceboxContent,
    callback: function () {
      setTimeout(function() {
        $.facebox(faceboxContent.show(), null, { delay: 200, target: `#${id}` });
      }, timeout);
    }
  })
};

recaptchaHandler.loadRecaptchaScript = function (options) {
  $.facebox(`<div class="g-recaptcha"
                  data-sitekey="${options.siteKey}"
                  data-callback="${options.successCallback}"></div>`);

  setTimeout(function() {
    var script = document.createElement('script');
    script.src = `https://www.recaptcha.net/recaptcha/api.js?onload=${options.onloadCallback}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }, 300);
}

// reCaptcha 2 callbacks

global.recaptchaV2LoadCallback = function () {
  mdFormHelper.hideSpinner();
}

global.recaptchaV2LoginCallback = function (response) {
  var form = $(".login-form:visible");

  form.find("[name='g-recaptcha-v2-response']").val(response);
  form.trigger('submit');
}

global.recaptchaV2SignUpCallback = function (response) {
  $(".g-recaptcha-v2-sign-up").val(response);
  $("#facebox:visible").find('.create-user-btn').trigger('click');
}

global.recaptchaV2PasswordResetCallback = function (response) {
  $(".g-recaptcha-v2-password-reset").val(response);
  $("#facebox:visible").find('.reset-password-form').trigger('submit');
}

global.recaptchaV2ContactUsCallback = function (response) {
  $(".g-recaptcha-v2-contact-us").val(response);
  $(".contact-us-form").trigger('submit');
}

//  added for fixing accessebility issue
document.addEventListener("turbo:load", function() {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      const textareas = document.querySelectorAll("textarea.g-recaptcha-response");

      textareas.forEach((textarea) => {
        if (!textarea.previousElementSibling || textarea.previousElementSibling.tagName !== 'LABEL') {
          const label = document.createElement("label");
          label.setAttribute("for", textarea.id);
          label.innerText = "Captcha Response";

          textarea.before(label);
        }
      });

      if (textareas.length > 0) {
        observer.disconnect();
      }
    });
  });

  observer.observe(document.body, { childList: true, subtree: true });
});
