global.remove_attachment = function (link) {
  $(link).parents("li").remove();
}

global.add_attachment = function (){
  var attachment_number = $("#facebox").find("#attachments ul li").size();
  var id = "attachment_"+attachment_number;
  var klass = "attachment-hidden-params" + attachment_number;
  var html = '<input class="attachment" size="35" type="file" aria-label="Add attachment" onchange = "upload_file_into_message(\''+id+'\')" id ="'+id+'" name="attachments['+attachment_number+']" />';
  html += '<input type="hidden" class=' + klass + ' name="attachments['+attachment_number+']" />';
  html += '<a class="remove" onclick="remove_attachment(this); return false;" href="#" aria-label="Remove attachment"></a>';
  $("#facebox").find("#attachments ul").append('<li>' + html + '</li>');
  return false;
}

global.showMessageByType = function (section_id, message_type, section_to_hide){
  $("#facebox").find("#"+ section_to_hide).addClass("hidden");
  $("#facebox").find("#"+ section_id).toggleClass("hidden");

  var type_elem = $("#facebox").find("#message_type");
  if (type_elem.val() == message_type){
    type_elem.val("message");
  }else{
    type_elem.val(message_type);
  }
}

global.loadMessageThread = function (threadLink, data) {
  if (!threadLink.length) return;

  $.get(threadLink.attr('href'), data || {}, function(response) {
      $('.communication-records .message-link.selected-row').removeClass('selected-row');
      threadLink.addClass('selected-row');
      if (response.viewed) {
        if (response.readCount !== null || response.unreadCount !== null) {
          updateSectionUnreadMessagesCount({ viewed: true, readCount: response.readCount, unreadCount: response.unreadCount });
          threadLink.removeClass('bold');
        }

        updateTotalUreadMessagesCount(response.totalUnreadCount);
      }

      link = "<a class='communication-records-back md-btn md-primary-btn' href='#'>Back to Messages</a>";

      var selectedMessage = $('.communication-container .selected-message').html(link + response.html);
      selectedMessage.find('a[rel*=facebox]').facebox();

      selectedMessage.find('.communication-records-back').on('click', function() {
        showCommunicationBlock('.communication-records');
      });

      showCommunicationBlock('.selected-message');
  }, 'json');
};

global.updateTotalUreadMessagesCount = function (count) {
  $('.unread-mssages-count').text(`Communications (${count})`);
};

global.updateSectionUnreadMessagesCount = function (options) {
  var communicationType = $('.communication-types .selected-row'),
      attrName = 'data-communication-count-value';

  if (options.unreadCount !== undefined) {
    communicationType.attr(attrName, options.unreadCount);
  } else {
    var count = communicationType.attr(attrName)

    if (count && options.readCount) {
      var parsedCount = parseInt(count) || 0,
          changedMessagesNumber = parseInt(options.readCount) * (options.viewed ? -1 : 1),
          unreadCount = parsedCount + changedMessagesNumber;

      if (unreadCount < 0) {
        unreadCount = 0;
      }

      communicationType.attr(attrName, unreadCount);
    }
  }
};

function validate_new_message(){
  var error_box = $("#facebox").find(".error");
  if ( $("#facebox").find("#message_recipient_id").val() == null){
    error_box.text("Please select candidates to send message.");
    return false;
  }

  if ($("#facebox").find("#message_subject").val() == ""){
     error_box.text("Subject cannot be blank.");
    return false;
  }

  if ($("#facebox").find("#message_body").val() == ""){
    error_box.text("Body cannot be blank.");
   return false;
  }

  var attachment_error = false;
  $("#facebox").find("#attachments .attachment:visible").each(function(index, attach){
     if ($(attach).val() ==""){
       attachment_error = true;
       return
     }
  });
  if (attachment_error){
    error_box.text("Please add attachment or remove the attachment field.");
    return false;
  }
  
  if ($("#facebox").find("#message_type").val() == "interview" && $("#facebox").find("#interview_job_title").val() == ""){
    error_box.text("Job title cannot be blank.");
   return false;
  }
  if ($("#facebox").find("#message_type").val() == "offer" && $("#facebox").find("#offer_job_title").val() == ""){
    error_box.text("Job title cannot be blank.");
   return false;
  }
  return true;
}

global.send_new_message = function (event){
   if (!validate_new_message()){
     event.preventDefault();
   }
}

global.send_new_request_ajax = function (element, prefix){
    var object_name  = prefix + "_request",
        candidate_id = $(element).parent().find("#"+object_name+"_candidate_id").val(),
        request_type = $(element).parent().find("#"+object_name+"_request_type").val(),
        purpose = $(element).parent().find("#"+object_name+"_purpose").val(),
        url = $(element).parent().find("#"+object_name+"_request_url").val();
    $.ajax({
        type: "POST",
        data: { request : {candidate_id: candidate_id, request_type: request_type, purpose : purpose}},
        url: url,
        dataType: "json",
        success: function (result) {
            $("#facebox").find(".close").click($.facebox.close);
            jQuery.facebox(result.notice);
        }
    });
}

global.upload_file_into_message = function (id) {
   $("#facebox").find(".error").text("");
   $("#facebox").find("#upload_process_image").show();
   $("#facebox").find("#send_message_button").hide();
  asyncUpload("/attachments/validate_upload", id);
  return false;
}

function asyncUpload(urlUploadTo, fileInputId){
  var formData = new FormData();
  formData.append('io', $("#" + fileInputId)[0].files[0]);

  $.ajax({
    url : urlUploadTo,
    type : 'POST',
    data : formData,
    processData: false,
    contentType: false,
    success: function (data, status){
      if(typeof(data.error) != 'undefined' && data.error != ''){
         $("#facebox").find(".error").text(data.error);
         $("#"+fileInputId).val("");
      } else {
        if (data.uploaded_data){
          var parsedData = JSON.parse(data.uploaded_data),
              id = fileInputId.split("_")[1];
          $("#facebox #" + fileInputId).parent().prepend("<span class='facebox-message-attachment'>" + parsedData.filename + "</span>");
          $("#facebox .attachment-hidden-params" + id).val(data.uploaded_data);
          $("#facebox #" + fileInputId).hide();
        }
      }
      $("#facebox").find("#upload_process_image").hide();
      $("#facebox").find("#send_message_button").show();
    },
    error: function (data, status, e){
      $.facebox(e);
    }
  });
}

function load_date_time(){
  $(".local_time").each(function() {
    var seconds       = parseInt($(this).attr("time")),
        date          = new Date(seconds),
        formattedDate = date.toLocaleTimeString('en-US', {
          year:   '2-digit',
          day:    '2-digit',
          month:  '2-digit',
          hour:   '2-digit',
          minute: '2-digit'
        });

    $(this).text(formattedDate);
  });
}

function setCommunicationLocation(){
  var location = $("#send_message #communication_location");
  if (location.length != 0){
    location.val(window.location.href);
  }
}


global.showCommunicationBlock = function (sectionName) {
  var allSections = $('.communication-types, .communication-records, .selected-message');

  if ($('.communication-container').width() < 1200) {
    allSections.hide();
    $(sectionName).show();
  } else {
    allSections.show();
  }
};

var isMessageFromUrlShown;

var showMessageByUrlParams = function () {
  var recordTypes = ['interview', 'offer', 'message'];

  recordTypes.forEach(function(type) {
    var recordId = urlHandler.getParam(`${type}_id`);

    if (recordId) {
      var record = $(`.message-link[href='/${type}s/${recordId}']`);

      if (record.length) {
        var sectionId = record.parents('.communication-section').attr('id');

        if (sectionId.length) {
          $(`.communication-types a[href='#${sectionId}']`).trigger('click');
          record.trigger('click');
          isMessageFromUrlShown = true;
        }
      }
    }
  });
};

var showSmsByUrlParams = function () {
  var candidateId = urlHandler.getParam('sms_candidate_id'),
      turboFrameLink = document.getElementById('sms-communications-link');

  if (candidateId && turboFrameLink) {
    var href = turboFrameLink.getAttribute('href');
    turboFrameLink.setAttribute('href', href + `?candidate_id=${candidateId}`);

    setTimeout(function() {
      turboFrameLink.click();
      turboFrameLink.setAttribute('href', href);
    }, 1000);
  }
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  $('#communication_navigation a').click(function() {
    $('#communication.more_details .title').hide_toggle_bar();
    $("#communication " + $(this).attr('href') + " .title").show_toggle_bar();

  });

  load_date_time();
  setCommunicationLocation();

  $(".destroy_multiple").on("click", function(event) {
    var controller = jQuery(this).attr("controller");
    var messages = jQuery("input[name='" + $(this).attr("communication_type") + "']:checked");
    messages.each(function(index){
      jQuery.ajax({
        type: "DELETE",
        url: "/" + controller + "/" + this.value + ".js", 
        success: function() {
          if (index == messages.length - 1) {
            document.location.reload();
          }
        }});
    });
    return false;
  });

  $(".respond_interview_action").on("click", function(event) {
    var status = $(this).attr("status");
    $(".edit_interview").find("#interview_status").val(status);
  });

  $(".respond_offer_action").on("click", function(event) {
    var status = $(this).attr("status");
    $(".edit_offer").find("#offer_status").val(status);
  });

  $("#search_query_program_program_id").change(function(){
    var id = $(this).val();
    if (id)
      $.ajax({ url: '/messages/new', type: 'GET', dataType: 'script' });
    else
      $('#search_query_program_field_of_interest_id').resetOptions().change();
  });

  // --- Material UI

  $('.communication-types a').on('click', function(e) {
    $(".communication-section").addClass('invisible');

    $('.communication-types a').removeClass('selected-row');
    $(this).addClass('selected-row');

    showCommunicationBlock('.communication-records');

    var turboFrame = $(this).data('turbo-frame'),
        sectionType = turboFrame ? `#${turboFrame}` : $(this).attr('href');

    $(".communication-section" + sectionType).removeClass('invisible');
    $('#selected_message').html('');

    $.cookie('communication-selected-section', sectionType, { path: '/' });

    if (turboFrame) {
      $('#selected_message').removeAttr('target');
    } else {
      $('#selected_message').attr('target', '_top');
      e.preventDefault();
    }
  });

  $('.communication-records .communication-records-back').on('click', function() {
    showCommunicationBlock('.communication-types');
  });

  $('.communication-container .message-link').on('click', function (event) {
    var target = $(event.target);

    if (target.hasClass('md-choise-button') || target.hasClass('md-choise') ||
        target.is('input')) {

      return;
    }

    loadMessageThread($(this));

    event.preventDefault();
  });

  $('.communication-records .message-link :checkbox').on('change', function () {
    if (!$(this).is(':checked')) {
      var section = $(this).parents('.communication-section');
      section.find('.communication-select-all :checkbox').prop('checked', false);
    }
  });

  $('.communication-select-all input').on('click', function(){
    var isChecked = $(this).is(':checked'),
        checkboxes = $(this).parents('.communication-section-content').find('.row-checkbox');

    checkboxes.prop('checked', isChecked).parents('tr').addClass('selected');
  });

  if (window.location.hash) {
    $(".communication-types a[href='" + window.location.hash + "']").trigger('click');
  } else {
    showMessageByUrlParams();
    showSmsByUrlParams();

    if (!isMessageFromUrlShown) {
      var communicationSectionHref = $.cookie('communication-selected-section'),
          communicationSection;

      if (communicationSectionHref) {
        communicationSection = $(`.communication-types a[href='${communicationSectionHref}']`);
      }

      if (!communicationSection || !communicationSection.length) {
        communicationSection = $(".communication-types a:first");
      }

      communicationSection.trigger('click');
    }
  }
});
