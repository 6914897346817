function clearContactsLocation(){
  var path = location.href.match(new RegExp('\/contacts\/.+\/callback'));
  if(!$.browser.msie && path && path[0]){
    var pathParts = path[0].split("/");
    pathParts.pop();
    window.history.replaceState("", "", pathParts.join("/"));
  }
}

function deleteReferralRow(){
  var rows;
  $.each(["#referrals table.referrals-table tr", "#facebox #referrals table.referrals-table tr"], function(index, value){
    rows = $(value + ":visible");
    if(rows.length > 1 ){
      rows.find("td.remove").removeClass("invisible");
    }else{
      $(rows[0]).find("td.remove").addClass("invisible");
    }
  })
}

global.resend_invite_email = function (referrer_token){
  $.ajax({
    url : "/resend_email/" + referrer_token,
    beforeSend: function(){
      newFlash();
    },
    success: function(data, textStatus, jqXHR) {
      showFlash("notice", "<strong>notice:</strong> Email has been resent successfully!");
    },
    error: function(jqXHR, textStatus, error) {
      showFlash("error", "<strong>error:</strong> Could not resend email! Try again later.");
    }
  })
}

global.resetReferralErrors = function (){
  $.each($("#facebox table.referrals-table tr"), function(index, row){
    $.each(["name", "email"], function(i, name){
      regexp = new RegExp("referral_" + name+ "_error");
      error = $(row).find("div").filter(function(){ return this.id.match(regexp)})[0];
      $(error).prop("id", ("referral_" + name + "_error" + (index + 1)));
    })
  })
}

function copyReferralElement(name, row, rowNumber, event){
  var input = row.find(":input." + name);
  var label = row.find("label." + name);
  var regexp = new RegExp("referral_" + name+ "_error" + rowNumber);
  var element = row.find("div").filter(function(){ return this.id.match(regexp)})[0];
  if (element && typeof(rowNumber) == "number"){
    $(element).prop("id", ("referral_" + name + "_error" + (rowNumber + 1)));
    $(input, label).prop("id", ("referral_" + name + (rowNumber + 1)));
    $(label).prop("for", ("referral_" + name + (rowNumber + 1)));
  }
}

function displayReferrerErrors(data){
  var error;
  var facebox = $("#facebox");
  $.each(["name", "email", "invite_message"], function(index, value){
    error = facebox.find("#referrer_" + value + "_error")
    if(error.length != 0){
      if(data[value]){
        error.html("<div class='facebox-single-error'>" + data[value] + "</div>");
        $(error).siblings("input, textarea").addClass("error_input_border");
      }else{
        error.html("");
        error.siblings("input, textarea").removeClass("error_input_border");
      }
    }
  })
}

function displayReferralError(name, data, index){
  if (data.email || data.name){
    var error = $("#facebox div#referrals table").find("div").filter(function(){
      var regexp = new RegExp("referral_" + name + "_error" + (index + 1));
      return this.id.match(regexp);
    });
    if(error && data[name]){
      error.html("<div class='facebox-single-error'>" + data[name] + "</div>");
      error.siblings("input").addClass("error_input_border");
    } else {
      error.html("");
      error.siblings("input").removeClass("error_input_border");
    }
  }
}

function cleanUpBlock(row){
  $(row).find("input").removeClass("error_input_border");
  $(row).find("td > span").remove();
  $(row).find(".referral_error").html("");
}

global.showReferralSuccessPopup = function (token){
 var successBlock = $(".referral-succes-block");
 if (successBlock.length != 0){
   $.facebox(successBlock.html());
   var closeLink = $("#facebox .close");
   if(closeLink.length != 0 ){
     closeLink.replaceWith('<a href="#" class="referral-close close"><img src="/assets/facebox/closelabel.gif" title="close" alt="close word" class="close_image"></a>');

     if ($(".dashboard_popup[value=true]").length == 0){
       closeLink.on("click", function(){
         $(document).trigger("close.facebox");
       });
     }
   }

   if (token){
     var link, href;
     $.each([".track-referrals", ".resend-referrals"], function(index, value){
       link = $("#facebox " + value);
       if(link.length != 0){
         href = link.prop("href").replace('token_param', '') + token;
         link.prop("href", href);
       }
     })
   }
 }
}

function cleanAllReferralErrors(){
  var facebox = $("#facebox");
  facebox.find(".referral_error").html("");
  facebox.find(".error_input_border").removeClass("error_input_border");
}

function inviteFromMailServices(){
  var form = $(".mail-services-invite-form");
  var referrerName = $("#facebox .referrer_name").val();
  var referrerEmail = $("#facebox .referrer_email").val();
  var referrerMessage = $("#facebox .referrer_invite_message").val();
  $("#mail-service-referrer-name").val(referrerName);
  $("#mail-service-referrer-email").val(referrerEmail);
  $("#mail-service-referrer-message").val(referrerMessage);
  if (form.length != 0) createReferrals(form);
  return false;
}

global.updateReferralMessage = function (){
  var referrerMessage = $("#facebox .referrer_invite_message").val().trim();
  if (referrerMessage == ""){
    $("#facebox #referrer_invite_message_error").text("Message cannot be blank.")
    $("#facebox .referrer_invite_message").addClass("error_input_border");
    return false;
  } else {
    $("#mail-service-referrer-message").val(referrerMessage);
    $(document).trigger('close.facebox');
  }
}

global.validateReferrer = function (){
  var name = $("#referrer_name").val();
  var email = $("#referrer_email").val();
  $.ajax({
    type: "POST",
    url: "/validate_referrer",
    data: {referrer: {name: name, email: email}},
    success: function(data) {
      if (data.errors){
        $(".referral_error").html("");
        $(".error_input_border").removeClass("error_input_border");
        $(["email", "name"]).each(function(i, v){
          var error = data.errors[v];
          if(error){
            var requiredBlock = $(".referrer-credentials .referrer-" + v);
            if (requiredBlock.length != 0){
              requiredBlock.find(".referral_error").text(error);
              requiredBlock.find("input:visible").addClass("error_input_border");
            }
          }
        })
      } else {
        sendReferralMessages();
      }
    },
    dataType: "json"
  });
}

function createReferrals(form){
  $.ajax({
    type: "POST",
    url: "/referrals",
    data: $(form).serialize(),
    success: function(data) {
      if (data){
        if (data.errors){
          cleanAllReferralErrors();
          displayReferrerErrors(data.errors.referrer);
          $.each(data.errors.referrals, function(index, data){
            $(["name", "email"]).each(function(i, v){
              displayReferralError(v, data, index);
            })
          })
        }else{
          showReferralSuccessPopup(data.access_token);
        }
      }
    },
    dataType: "json"
  });
}

function invokeReferralPopup(){
  if ($(".dashboard_popup").length == 0){
    if (location.href.match(/referrer=true/)){
      showReferralForm();
    } else if (location.href.match(/referral_saving=true/)) {
      showReferralSuccessPopup("");
    }
  }
}

function updateReferrer(){
  $.facebox($("#mail-service-referrals-form").html());
  return false;
}

global.updateReferralList = function (){
  $.facebox($("#referral-list-popup").html());
  setTimeout(function(){
    $("#facebox .empty-referrals-error").text("");
    $("#facebox .select-referrals-block .invite-referral:checkbox").each(function(){
      var selector = $(this).prop("id");
      if (selector) {
        var referralField = $(".contacts-block" + " ." + selector + ":visible");
        $(this).prop("checked", referralField.length != 0);
      }
    })
  }, 50)
}

global.personalizeMessage = function (){
  $.facebox($("#mail-service-referrals-form").html());
  var message = $("#mail-service-referrer-message").val();
  if (message) $("#facebox .referrer_invite_message").val(message);
}

function toggleReferralScroll(checkboxes){
  if (checkboxes.filter(":checked").length > 8)
    $(".contacts-block").addClass("sroll_search_box")
  else
    $(".contacts-block").removeClass("sroll_search_box")
}

global.setReferralsToForm = function (){
  var checkboxes = $("#facebox .select-referrals-block .invite-referral:checkbox:visible");

  if (checkboxes.filter(":checked").length == 0){
    $("#facebox .empty-referrals-error").text("Please select at least one contact to refer");
    return false
  }

  checkboxes.each(function(){
    var selector = $(this).prop("id");
    if (selector){
      var referralField = $(".contacts-block" + " ." + selector);
      if (referralField.length != 0){
        if ($(this).is(":checked")){
          referralField.show();
          referralField.removeAttr("disabled");
        } else {
          referralField.hide();
          referralField.find("input").prop("disabled", true);
        }
      }
    }
  })

  toggleReferralScroll(checkboxes);
  $(document).trigger('close.facebox');
}

global.sendReferralMessages = function (){
  var form = $(".mail-services-invite-form");
  if (form.length != 0) form.submit();
}


global.socialSharing = {
  windowStyles: 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0',

  url: function () {
    return $('#referral_root_url').val() || "https://talify.com";
  },

  encodedUrl: function () {
    return encodeURIComponent(this.url());
  },

  linkedin: function () {
    var linkedinUrl = 'http://www.linkedin.com/shareArticle?mini=true&url=';
    window.open(linkedinUrl + this.encodedUrl(), '', this.windowStyles);
  },

  twitter: function () {
    window.open('https://twitter.com/share?url=' + this.encodedUrl(), '', this.windowStyles);
  },

  facebook: function () {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + this.encodedUrl(), '', this.windowStyles);
  },

  whatsapp: function () {
    var wallPostInfo = $('#referral_email_body').val() || "";
    window.open('whatsapp://send?text=' + encodeURIComponent(wallPostInfo) + " " + this.encodedUrl());
  },

  email: function () {
    var subject = $('#referral_email_subject').val() || "",
        body = $('#referral_email_body').val() || "";

    body = body + "\n\n" + this.url();

    body = encodeURIComponent(body);
    subject = encodeURIComponent(subject);
    window.open("mailto:?subject=" + subject + "&body=" + body);
  }
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  if(disableFileLoading('referral')) return;

  invokeReferralPopup();
  deleteReferralRow();
  clearContactsLocation();
  $("body").on("click", "td.remove", function(){
    $(this).closest("tr").remove();
    deleteReferralRow();
    resetReferralErrors();
  });

  $('body').on("click", "#facebox .add-more-emails, .add-more-emails", function(event){
    if($("#facebox:visible").length != 0){
      var table = $("#facebox table.referrals-table");
    }else {
      var table = $("table.referrals-table");
    }
    var rowNumber = table.find("tr").length;
    var lrow = table.find("tr:last").clone();
    cleanUpBlock(lrow);
    $.each(["name", "email"], function(index, value){
      copyReferralElement(value, lrow, rowNumber, event);
    });
    lrow.find(":text").val("");
    table.append(lrow);
    deleteReferralRow();
    return false;
  });

  $('body').on("submit", "#facebox #referrals form", function(){
    createReferrals(this);
    return false;
  });

  $("body").on("change", '#select-all-referrals', function(){
    $('.invite-referral').prop("checked", $(this).is(":checked"));
  });

  $("body").on("click", '#manual-invite', function(){
    $("#facebox .mail-services-popup form").show();
    return false;
  });

  $(document).bind('beforeReveal.facebox', function() {
    setTimeout(function(){
      deleteReferralRow();
      resetReferralErrors();
      var messages = $("#facebox .ordered_error_messages");
      if (messages.length > 0){
        messages.hide();
      }
    }, 5);
  });
});
